:root {

    /* Colors */
    --amazonOrange: #009900; /* Redefined to dark green */
    --lightAmazonOrange: #03b103;
    --darkAmazonOrange: #027e02;
    --squidInk: #232F3E;
    --lightSquidInk: #31465F;
    --deepSquidInk: #152939;
    --grey: #828282;
    --lightGrey: #C4C4C4;
    --silver: #E1E4EA;
    --darkBlue: #31465F;
    --red: #DD3F5B;
    --white: #FFFFFF;
    --light-blue: #00a1c9;


    --color-primary: #009900; 
    --color-primary-highlight: #03b103;

    --gradient-blaze: linear-gradient(270deg, #009900 0%, #009900 100%);
    --ion-color-primary: #009900;
    --ion-color-primary-tint: #009900;
    --ion-color-tertiary: #009900;
    --ion-color-tertiary-tint: #009900;
    --ion-color-warning: #009900;
    --ion-color-warning-shade: #009900;
    --ion-color-warning-tint: #009900;
    }